import React, { useState } from 'react';
import Message from './Message';

const backendUrl = process.env.REACT_APP_LEIJEPH_BACKEND_URL;
interface IMessage {
  role: string;
  content: string;
  cost: string;
}

const ASK_FOR_PASSWORD_S = "请输入密码。"
const PROCESSING_S = "处理中..."
const PROCESSING_ERROR_S = "发生错误, 请刷新页面。"

const initialMessages: IMessage[] = [
  { role: 'assistant', content: ASK_FOR_PASSWORD_S, cost: '' },
];

const ChatApp: React.FC = () => {
  const [messages, setMessages] = useState<IMessage[]>(initialMessages);
  const [inputValue, setInputValue] = useState<string>(localStorage.getItem('password') || '');

  const handleSendMessage = async (): Promise<void> => {
    const currentMessage = inputValue.trim();

    if (messages[messages.length - 1].content === ASK_FOR_PASSWORD_S) {
      localStorage.setItem('password', inputValue);
    }

    const allMessages = [
      ...messages,
      { role: 'user', content: currentMessage, cost: '' },
      { role: 'assistant', content: PROCESSING_S, cost: '' }
    ];

    setMessages(allMessages);
    setInputValue('');

    try {
      const response = await fetch(`${backendUrl}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ messages: allMessages }),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const responseData = await response.json();
      // console.log('Response:', responseData);

      // Replace the last message (i.e., PROCESSING_S) using the actual response
      setMessages((prevMessages) => {
        const newMessages = prevMessages.slice(0, -1); // Remove the last message
        return [...newMessages, { role: 'assistant', content: responseData.content, cost: responseData.cost }];
      });

    } catch (error) {
      console.error('Failed to send message:', error);
      // Replace the temporary message with an error message
      setMessages((prevMessages) => {
        const newMessages = prevMessages.slice(0, -1); // Remove the last message
        return [...newMessages, { role: 'assistant', content: PROCESSING_ERROR_S, cost: '' }];
      });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <div className="messages-list">
        {messages.map((message, index) => (
          <Message key={index} role={message.role} content={message.content} cost={message.cost} />
        ))}
      </div>
      <div className="disclaimer">
        聊天机器人的回答仅供参考，可能会有错误。
        <br />
        对于重要的信息，请通过其他途径进行二次确认。
      </div>
      <div className="input-area">
        <textarea
          rows={3}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <button onClick={handleSendMessage} disabled={inputValue.trim().length === 0 || messages[messages.length - 1].content === PROCESSING_S}>
          发送
        </button>
      </div>
    </div>
  );
};

export default ChatApp;
